import { Box, setRef } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import BasicRouter from "react-basic-routing";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import { routes } from "./library/Routes";
import Page404 from "./views/Page404";

export default function Root() {
    const isMobile = React.useRef(window.innerWidth < 480);
    React.useEffect(() => {
        setRef(isMobile, window.innerWidth < 480)
    });
    const useStyles = makeStyles({
        root: {
            margin: "auto",
            marginBottom: "2.5em",
            marginTop: "2.5em",
            width: isMobile.current ? "85%" : "75%",
        }
    });

    const classes = useStyles();
    const beforeRoutes = (<>
        <Header />
    </>)
    const afterRoutes = (<>
        <Footer />
    </>)

    return <>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            minHeight: "100vh"
        }} component="div">
            <BasicRouter
                routes={routes}
                routesClassName={classes.root}
                beforeRoutes={beforeRoutes}
                afterRoutes={afterRoutes}
                windowTitle="Mumme-IT"
                error404={{
                    name: "404",
                    child: <Page404 />
                }}
            />
        </Box>
    </>
}