import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Link } from 'react-basic-routing';
import { useCheckMobileScreen } from '../../helper/Tools';

export interface ITileProps {
    imageUrl: string,
    title: string,
    href?: string,
    to?: string,
    description?: string,
    padImage?: boolean,
    titleBackgroundColor?: string
    titleTextColor?: string
}
const Tile: React.FunctionComponent<ITileProps> = (props: ITileProps) => {

    const isMobile = useCheckMobileScreen()

    const Description = () => {
        if (!props.description) {
            return (<></>);
        }
        return (<>
            <CardContent>
                <Typography variant='body1'>{props.description}</Typography>
            </CardContent>
        </>);
    }

    const ActualTile = () => {
        return (<><Card sx={{ ":hover": { boxShadow: "5px 4px 12px 5px #7E7E7E", cursor: "pointer" }, maxHeight: "27em", maxWidth: "18em", height: "100%" }}>
            <CardMedia sx={{ height: "12em", width: isMobile ? "14em" : "18em", background: theme => theme.palette.primary.light }} >
                <Grid container justifyContent="center">
                    <Box component="img" sx={{
                        height: props.padImage ? "10em" : "12em",
                        padding: props.padImage ? "1em" : "0",
                        filter: props.padImage ? "invert(93%) sepia(0%) saturate(7483%) hue-rotate(313deg) brightness(114%) contrast(101%)" : ""
                    }} src={props.imageUrl} />
                </Grid>
            </CardMedia>
            <Typography sx={{
                backgroundColor: props.titleBackgroundColor ?? "primary.main",
                textAlign: "center",
                height: "1.5em",
                lineHeight: "1.5em",
                color: props.titleTextColor ?? "white"
            }}
                variant='h5' >{props.title}</Typography>
            <Description />
        </Card></>);
    }
    if (props.to) {
        if (props.href) {
            console.warn("both href and to are defined in Tile component. Only to will be considered");
        }
        return (<>
            <Box component={Link} to={props.to} sx={{ textDecoration: "none" }} >
                <ActualTile />
            </Box>
        </>);
    } else if (props.href) {
        return (<>
            <Box component="a" href={props.href} target="_blank" sx={{ textDecoration: "none" }} >
                <ActualTile />
            </Box>
        </>);
    } else {
        return (<><ActualTile /></>)
    }
}

export default Tile;