import { Grid, Typography } from "@mui/material";
import React from "react";
import Tile from "../components/navigation/Tile";
import TileField from "../components/navigation/TileField";
import { useCheckMobileScreen } from "../helper/Tools";

export interface IAboutProps {

}


const About: React.FunctionComponent<IAboutProps> = (props: IAboutProps) => {
    const isMobile = useCheckMobileScreen()
    return (
        <>
            <Grid container justifyContent="center" rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">Über mich</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center"> Ich bin Felix Mumme, ein Software-Engineer mit dem Fokus auf Front-End-Entwicklung mit React, sowie Backend-Entwicklungen mit Spring-Boot (Java oder Kotlin) oder Golang </Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                    <TileField tilesPerRow={2}>
                        <Tile padImage imageUrl="svg/mail.svg" title='Kontakt' to="/contact" description=""></Tile>
                    </TileField>
                </Grid>
            </Grid>
        </>
    );
}

export default About;