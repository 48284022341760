
import { Grid, Button, Typography} from '@mui/material';
import { Box } from '@mui/system';
import React from "react";
import { Link } from "react-basic-routing";


export default function Footer() {
    return (<>
        <Box sx={{
            position: "sticky",
            width: "auto",
            marginTop: "auto",
            left: 0,
            right: 0,
            background: theme => theme.palette.primary.dark,
            paddingLeft: "1em",
            paddingRight: "1em",
        }}>
            <Grid container>
                <Grid item xs={6} container justifyContent="left" alignContent="center">
                    <Typography color="secondary" variant="body2" align='center' textAlign="center">
                        © 2022 Felix Mumme. All Rights reserved. Uicons by <Box component="a" target="_blank" sx={{ textDecoration: "none", color: theme => theme.palette.secondary.dark }} href="https://www.flaticon.com/uicons">Flaticon</Box>
                    </Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="right" alignContent="center">
                    <Button color="secondary" size='small' variant="text" component={Link} to="/impressum">Impressum</Button>
                </Grid>
            </Grid>
        </Box >
    </>)
}