import * as React from 'react';
import { Grid, Paper } from '@mui/material';
import { useCheckMobileScreen } from '../../helper/Tools';

export interface ITileFieldProps {
    tilesPerRow?: number
}
const TileField: React.FunctionComponent<ITileFieldProps> = (props: React.PropsWithChildren<ITileFieldProps>) => {

    const isMobile = useCheckMobileScreen()
    return (<>
        <Paper sx={{ padding: "2em" }}>
            <Grid container justifyContent={"center"} spacing={3}>
                {React.Children.map(props.children, child => (<Grid item
                    xs={isMobile ? 12 : (12 / (props.tilesPerRow ?? 4))}
                    container
                    justifyContent="center"
                    alignContent="center">
                    {child}
                </Grid>))}
            </Grid>
        </Paper>

    </>);
}

export default TileField;