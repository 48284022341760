import { Grid, Typography } from '@mui/material';
import * as React from 'react';

export interface IImpressumProps {
}

const Impressum: React.FunctionComponent<IImpressumProps> = (props: IImpressumProps) => {
    return (
        <>
            <Grid container justifyContent="center" rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">Impressum</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">Angaben gem&auml;&szlig; &sect; 5 TMG</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">Felix Mumme<br />
                        Software-Engineer<br />
                        Friedhofweg 1<br />
                        89340 Leipheim</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">Kontakt</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        Telefon: +49 151 598 252 59<br />
                        E-Mail: felix.mumme@mumme-it.de
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">Umsatzsteuer-ID</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                        64 380 972 119
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default Impressum;