import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import HttpsRedirect from 'react-https-redirect'
import Root from './Root';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2b2e4a",
      dark: "#3e426a"
    },
    secondary: {
      light: "#ffffff",
      main: "#AAABAB",
      dark: "#181a2a"
    },
    background: {
      paper: "#c4c4c4"
    }
  },
  overrides: {}
});

function App() {
  return (
    <>
      <HttpsRedirect>
        <ThemeProvider theme={theme}>
          <Root />
        </ThemeProvider>
      </HttpsRedirect>
    </>
  );
}

export default App;
