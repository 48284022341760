import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Typography, Theme } from '@mui/material';

export interface IPage404Props {

}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(1),
        }
    })
);

const Page404: React.FunctionComponent<IPage404Props> = (props: IPage404Props) => {
    const classes = styles();
    const [joke, setJoke] = React.useState()
    const [fetching, setFetching] = React.useState(false)

    if (!fetching) {
        setFetching(true)
        fetch('https://api.chucknorris.io/jokes/random')
            .then(res => res.json())
            .then(res => setJoke(res.value))
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>Sorry, we didn't found the page you were looking for :-(</Typography>
            <br />
            <Typography variant="h4">But hey, we got a joke for you!</Typography>
            <br />
            <Typography variant="body1">{joke}</Typography>
        </>
    );
}

export default Page404;