import { AppBar, Toolbar, Button, Box, Grid } from "@mui/material";
import * as React from 'react';
import { NavLink } from "react-basic-routing";


export interface IHeaderProps {
}

const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {

    return (<>
        <AppBar position="sticky">
            <Toolbar>
                <Grid container >
                    <Grid item xs={3} container alignContent="center">
                        <Button color="primary" variant="text" sx={{ width: "2em" }} component={NavLink} to="/" >
                            <Box component="img" src="svg/icon_no_background.svg" sx={{ width: "100%", height: "100%" }} />
                        </Button>
                    </Grid>
                    <Grid item xs={9} container justifyContent="right" alignContent="center">
                        <Button color="secondary" variant="text" component={NavLink} to="/contact" >
                            Jetzt Kontakt aufnehmen!
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    </>)

}

export default Header;
