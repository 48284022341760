import { Grid, Typography } from '@mui/material';
import * as React from 'react';

export interface IKontaktProps {
}

const Kontakt: React.FunctionComponent<IKontaktProps> = (props: IKontaktProps) => {
    return (
        <>
            <Grid container justifyContent="center" rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">Kontakt</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">Ich schätze eine offene Kommunikation sehr und bin jederzeit für ein Gespräch bereit. Kommen Sie gerne auf mich zu!</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1"> Mail: <a href="mailto:felix.mumme@mumme-it.de">felix.mumme@mumme-it.de</a> </Typography>
                    <Typography variant="body1"> Telefon: +49 151 598 252 59 </Typography>
                </Grid>
                <Grid item xs={12}></Grid>

                <br />
            </Grid>
        </>
    );
}

export default Kontakt;