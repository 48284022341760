import React from "react";
import { BasicRoute, DynamicRoute } from "react-basic-routing";
import About from "../views/About";
import Home from "../views/Home";
import Impressum from "../views/Impressum";
import Kontakt from "../views/Kontakt";

export const routes: (BasicRoute | DynamicRoute)[] = [

    {
        path: "/",
        name: "Home",
        child: <Home />,
    },
    {
        path: "/about",
        name: "About",
        child: <About />,
    },
    {
        path: "/contact",
        name: "Kontakt",
        child: <Kontakt />,
    },
    {
        path: "/impressum",
        name: "Impressum",
        child: <Impressum />,
    }
]
