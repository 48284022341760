import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import Tile from '../components/navigation/Tile';
import TileField from '../components/navigation/TileField';
import { useCheckMobileScreen } from '../helper/Tools';

export interface IHomeProps {

}


const Home: React.FunctionComponent<IHomeProps> = (props: IHomeProps) => {

    const isMobile = useCheckMobileScreen()
    return (
        <>
            <Grid container justifyContent="center" rowSpacing={3}>
                <Grid item xs={12} container justifyContent="center" alignContent={"center"} >
                    <Box component="img" sx={{ height: isMobile ? "10em" : "7.5em" }} src="svg/color_logo_no_background.svg" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={isMobile ? "h5" : "h4"} align="center">Kompetente, lösungsorientierte und qualitative Softwareentwicklung!</Typography>
                </Grid>
                <Grid item xs={isMobile ? 12 : 8}>
                    <TileField tilesPerRow={2}>
                        <Tile padImage imageUrl="svg/info.svg" title='Über mich' to="/about" description=""></Tile>
                        <Tile padImage imageUrl="svg/mail.svg" title='Kontakt' to="/contact" description=""></Tile>
                    </TileField>
                </Grid>
            </Grid>
        </>
    );
}

export default Home;